var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"x-table elevation-1",attrs:{"headers":_vm.headers,"items":_vm.segment.groups,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function(groups){return [_c('tr',[_c('td',{attrs:{"rowspan":_vm.getGroupRowSpan(groups)}},[_vm._v(" "+_vm._s(groups.item.groupName)+" ")]),_c('td',{attrs:{"rowspan":_vm.getGroupRowSpan(groups)}},[_vm._v(" "+_vm._s(groups.item.groupName)+" ")]),_c('td',{attrs:{"rowspan":_vm.getGroupRowSpan(groups)}},[_vm._v(" "+_vm._s(groups.item.ratio)+" ")]),_c('td',{attrs:{"rowspan":_vm.getGroupRowSpan(groups)}},[_vm._v(" "+_vm._s(_vm.numberWithCommas(groups.item.userCount))+" ")])]),_vm._l((groups.item.tasks),function(task){return _c('tr',{key:task.id},[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.normalizeCount(task.count))+" ")]),_c('td',[_c('a',{on:{"click":() =>
                _vm.$router.push({
                  name: 'TaskDetail',
                  params: { taskId: task.id, viewOnly: true }
                })}},[_vm._v(_vm._s(task.name))])]),_c('td',[_vm._v(_vm._s(task.channel))]),_c('td',[_vm._v(_vm._s(_vm.$t(`Task.status_list['${task.status}']`)))]),_c('td',[_vm._v(_vm._s(_vm.$t(`Task.execute_status_list['${task.executeStatus}']`)))]),_c('td',{staticClass:"dateTime"},[_vm._v(" "+_vm._s(_vm.formatDatetimeFromISOString(task.startTime))+" ")]),_c('td',{staticClass:"dateTime"},[_vm._v(" "+_vm._s(_vm.formatDatetimeFromISOString(task.endTime))+" ")]),_c('td',{staticClass:"dateTime"},[_vm._v(" "+_vm._s(_vm.formatDatetimeFromISOString(task.createTime))+" ")]),_c('td',{staticClass:"actions-area"},_vm._l((_vm.taskActions.filter(
              scheme => !scheme.render || scheme.render(task)
            )),function(scheme,ind){return _c('x-button',_vm._b({key:ind,attrs:{"click":e => scheme.click(task, e)}},'x-button',scheme,false))}),1)])}),_c('tr',[_c('td',[(_vm.authenticate('TaskCreate'))?_c('v-btn',{staticClass:"btnPrimary",attrs:{"outline":"","small":"","disabled":!groups.item.isSend,"color":"blue darken-2"},on:{"click":() => _vm.goToCreateTask(groups.item.groupId)}},[_vm._v(" Add Task ")]):_vm._e()],1),_vm._l((new Array(_vm.headers.length - 6)),function(x){return _c('td')})],2)]}}])},[_c('template',{slot:"footer"},[_c('tr',{staticClass:"font-weight-bold"},[_c('td',[_vm._v("Total")]),_c('td'),_c('td',[_vm._v(_vm._s(_vm.totalPercentage)+"%")]),_c('td',[_vm._v(_vm._s(_vm.numberWithCommas(_vm.totalUserCount)))]),_c('td',{attrs:{"rowspan":_vm.headers.length - 5}})])])],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }