<template>
  <x-dialog
    v-bind="dialogSegmentContent"
    :value="value"
    @input="val => $emit('input', val)"
    @confirm="dialogConfirm"
  >
    <h3
      slot="slot-header"
      class="slot-header title"
    >
      Segment Content
    </h3>
    <div>
      <!-- BEGIN segment content -->
      <div class="segmentContent">
        <div class="pt-1 body-2">
          <p>
            <label class="labelStyle">ID : </label>
            {{ segmentId }}
          </p>
          <p>
            <label class="labelStyle">Description : </label>
            {{ segmentDescription }}
          </p>
          <p class="viewSegmentDetail">
            <a
              :href="
                `${dataPlatformUrl}/#/crm/viewPeopleLibrary?peopleLibraryId=${segmentId}`
              "
              target="_blank"
            >
              <i class="material-icons subheading">remove_red_eye</i>
              View segment detail
            </a>
          </p>
          <!-- hide rule -->
          <!-- <p>
            <label class="labelStyle">Rule : </label>
            {{ segments.rule }}
          </p>-->
          <!-- hide rule -->
        </div>
      </div>
      <!-- END segment content -->
      <x-table
        v-bind="tableScheme"
        class="mt-3"
      />
    </div>
  </x-dialog>
</template>

<script>
import { numberWithCommas } from '@/utils/number'
import { dataPlatform } from '@/const'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    segmentId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialogSegmentContent: {
        value: false,
        cancelText: null,
        confirmText: null,
        closeSign: true,
        maxWidth: '850px'
      },

      segmentName: '',
      segmentDescription: '',

      tableScheme: {
        multiSelectable: false,
        headers: [
          { value: 'groupId', text: 'Group id' },
          { value: 'groupName', text: 'Group name' },
          { value: 'ratio', text: '% of list' },
          {
            value: 'userCount',
            text: '# of list',
            render: item => numberWithCommas(item.userCount)
          }
          // { value: 'contactRule', text: 'Contact rule'}, //hide rule
        ],
        items: []
      },
      dataPlatformUrl: dataPlatform.URL[this.$store.env]
    }
  },
  watch: {
    async segmentId (val) {
      this.fetchSegmentDetail(val)
    }
  },
  mounted () {
    this.fetchSegmentDetail(this.segmentId)
  },
  methods: {
    dialogConfirm () {
      this.$emit('input', false)
    },
    async fetchSegmentDetail (segmentId) {
      this.$store.loading = true
      try {
        const { data } = await this.$Campaign.fetchSegmentDetail({
          segmentId
        })
        this.segmentName = data.id
        this.segmentDescription = data.description
        this.tableScheme.items = data.groups
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.segmentContent {
  p {
    margin: 0;

    label {
      color: $bluey-grey;
    }
  }
  .viewSegmentDetail {
    margin-top: 8px;
      a {
        font-weight: 700;
      }

    i {
      position: relative;
      top: 3px;
      margin-right: 6px;
    }
  }
}
</style>
