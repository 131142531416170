<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        Edit Campaign
      </h2>
      <v-spacer class="hidden-xs-only" />
      <div class="createInfo caption text-md-right mr-3">
        <p>
          <label class="labelStyle">Creator : </label>
          {{ createInfo.name }}
        </p>
        <p>
          <label class="labelStyle">Create Date : </label>
          {{ createInfo.date }}
        </p>
      </div>
    </v-layout>
    <!-- END header -->

    <CampaignForm
      v-if="campaign"
      :campaign="campaign"
    />
  </div>
</template>

<script>
import CampaignForm from './CampaignForm'
import { formatDatetimeFromISOString } from '../../../utils/date'

export default {
  components: {
    CampaignForm
  },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      campaign: undefined
    }
  },
  computed: {
    createInfo () {
      if (!this.campaign) return {}
      return {
        name: this.campaign.ownerName,
        date: formatDatetimeFromISOString(this.campaign.createTime)
      }
    }
  },
  async mounted () {
    this.$store.loading = true
    try {
      const { data } = await this.$Campaign.fetchCampaignDetail({
        campaignId: this.campaignId
      })
      this.campaign = data
    } catch (e) {
      this.$store.errorNotification = e
    }
    this.$store.loading = false
  }
}
</script>

<style lang="stylus" scoped>
.createInfo {
  p {
    margin: 0;
  }
}
</style>
