<template>
  <div>
    <div class="caption">
      Please go to
      <a
        :href="dataPlatformUrl"
        target="_blank"
      >Data Platform</a>
      generate customer list, afterward to assign customer group for each task.
    </div>
    <x-formgroup
      class="mt-3 w100 addSegmentSelect"
      @submit.prevent="() => $refs.submitButton.click()"
    >
      <x-select-filter
        class="layout flex sm12 column"
        v-bind="selectableSegments"
        :items="segmentOptions"
        @change="filterChanged"
      />
      <x-button
        ref="submitButton"
        class="btnPrimary"
        v-bind="buttonAdd"
      />
    </x-formgroup>

    <!-- BEGIN selected segment -->
    <label class="labelStyle d-block mt-3 mb-2">Selected Segments</label>

    <div class="selectedSegment bgPaleBlue pa-2">
      <ul class="mb-3">
        <div
          v-for="(data, ind1) of [
            { list: existedSegments, editable: false },
            { list: segments, editable: true }
          ]"
          :key="ind1"
        >
          <li
            v-for="(segment, ind) in data.list"
            :key="segment.id"
            class="pt-3 pl-2"
          >
            <x-tooltip v-bind="tooltipScheme">
              <p slot="activator">
                <a @click="() => openSegmentDetail(segment.id)">
                  <i class="material-icons subheading">remove_red_eye</i>
                  <span class="pl-1">
                    <span class="text-no-wrap font-weight-black">
                      (ID: {{ segment.id }})
                    </span>
                    <span>
                      {{ segment.description }}
                    </span>
                  </span>
                </a>
              </p>
            </x-tooltip>
            <v-spacer v-if="data.editable" />
            <x-button
              v-if="data.editable"
              v-bind="buttonUnselect"
              :click="() => removeSegment(ind)"
              class="btnWarning btnIconXs"
            />
          </li>
        </div>
      </ul>
    </div>
    <segment-detail-dialog
      v-if="segmentDetailDialogId"
      v-model="showSegmentDetailDialog"
      :segment-id="segmentDetailDialogId"
    />
    <!-- END selected segment -->
  </div>
</template>

<script>
import { dataPlatform } from '@/const'
import SegmentDetailDialog from '../components/SegmentDetailDialog'

export default {
  components: {
    SegmentDetailDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    existedSegments: {
      type: Array,
      default: () => []
    },
    selectedSegments: {
      type: Array,
      default: () => []
    },
    segmentOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataPlatformUrl: dataPlatform.URL[this.$store.env],
      dialogAddSegment: {
        cancelText: 'CANCEL',
        confirmText: 'SAVE',
        closeSign: true,
        maxWidth: '700px'
      },
      segments: [...this.selectedSegments],
      currentSelected: '',
      segmentDetailDialogId: '',
      showSegmentDetailDialog: false,
      selectableSegments: {
        placeholder: 'search ID',
        errorMessages: ''
      },

      buttonAdd: {
        content: 'Add',
        size: 'large',
        outline: true,
        prependIcon: 'add',
        click: () => {
          if (!this.currentSelected) return
          const segmentObj = JSON.parse(this.currentSelected)
          if (
            !this.segments.some(segment => segment.id === segmentObj.id) &&
            !this.existedSegments.some(segment => segment.id === segmentObj.id)
          ) {
            this.updateSelected()
            this.segments.push(segmentObj)
          } else {
            this.selectableSegments.errorMessages = 'Segment already existed'
          }
        }
      },

      buttonUnselect: {
        content: 'close',
        round: true,
        icon: true,
        size: 'small'
      },
      tooltipScheme: {
        content: 'View Segment Detail',
        direction: 'top',
        contentClass: 'tooltipClass'
      }
    }
  },
  watch: {
    selectedSegments (val) {
      this.segments = val
    }
  },
  methods: {
    removeSegment (index) {
      this.segments.splice(index, 1)
    },
    dialogConfirm (event, a) {
      this.$emit('input', false)
      this.$emit('confirm', this.segments)
    },
    filterChanged (val) {
      this.currentSelected = val
      this.selectableSegments.errorMessages = ''
    },
    updateSelected () {
      this.$emit('updateSelected', this.segments)
    },
    openSegmentDetail (val) {
      this.segmentDetailDialogId = val
      this.showSegmentDetailDialog = true
    }
  }
}
</script>

<style lang="stylus" scoped>
@media (min-width: 768px) {
    .addSegmentSelect {
      display: inline-flex;
    }
}

.addSegmentSelect >>> .v-select {
  margin-top: 5px;
}

.selectedSegment {
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;

      p {
        margin: 0;

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          i {
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }
}
</style>
