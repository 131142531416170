import { render, staticRenderFns } from "./CampaignList.vue?vue&type=template&id=304f5272&scoped=true&"
import script from "./CampaignList.vue?vue&type=script&lang=js&"
export * from "./CampaignList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304f5272",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('304f5272')) {
      api.createRecord('304f5272', component.options)
    } else {
      api.reload('304f5272', component.options)
    }
    module.hot.accept("./CampaignList.vue?vue&type=template&id=304f5272&scoped=true&", function () {
      api.rerender('304f5272', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Campaign/pages/CampaignList.vue"
export default component.exports