<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        Create Campaign
      </h2>
      <v-spacer class="hidden-xs-only" />
    </v-layout>
    <!-- END header -->

    <CampaignForm />
  </div>
</template>

<script>
import CampaignForm from './CampaignForm'

export default {
  components: {
    CampaignForm
  }
}
</script>

<style lang="stylus" scoped></style>
