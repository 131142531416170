<template>
  <div class="contentBoxWrap">
    <div class="contentBox">
      <hint class="mb-3">
        Execution time (or period) of each task in the campaign should be set
        within the range of campaign start time and end time.
      </hint>
      <v-layout>
        <v-flex
          md6
          offset-md3
        >
          <x-formgroup
            ref="form"
            v-model="scheme.valid"
            class="w100"
          >
            <!-- BEGIN input group -->
            <div class="pa-2">
              <x-textfield
                v-model="scheme.campaignName.value"
                v-bind="scheme.campaignName"
              />
              <x-textarea
                v-model="scheme.campaignDescription.value"
                v-bind="scheme.campaignDescription"
              />

              <div class="mb-4">
                <x-datetime-picker
                  v-if="isTimeEditable"
                  v-bind="scheme.datetimePeriod"
                  time-format="HH:mm"
                  @onChange="onDatetimePickerChange"
                />
                <x-textfield
                  v-else
                  v-bind="scheme.datetimePeriodDisabled"
                  :disabled="true"
                />
              </div>

              <div class="mt-2 mb-3">
                <label class="d-block labelStyle">
                  Campaign Segment
                  <span class="required-star">*</span>
                </label>
                <select-segment
                  :existed-segments="existedSegments"
                  :selected-segments="selectedSegments"
                  :segment-options="segmentOptions"
                  @updateSelected="val => (selectedSegments = val)"
                />
              </div>
            </div>
            <!-- END input group -->

            <v-layout class="actionBtnGroup">
              <x-button
                v-bind="scheme.buttonCancel"
                class="btnInfo"
              />
              <x-button
                v-bind="scheme.buttonSubmit"
                class="btnSecondary"
              />
            </v-layout>
          </x-formgroup>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import SelectSegment from '../components/SelectSegment'
import Hint from '@/components/Hint'
import {
  notEmpty,
  maxLength,
  greaterThanCurrentTime
} from '../../../utils/validation'
import {
  localDateToISOString,
  toDateObj,
  formatDatetimeFromISOString
} from '../../../utils/date'

export default {
  components: {
    Hint,
    SelectSegment
  },
  props: {
    campaign: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      data: {
        startTime: '',
        endTime: '',
        segments: [],
        ...this.campaign
      },
      segmentOptions: [],
      existedSegments: [],
      selectedSegments: [],
      task: {
        minStartTime: null,
        maxEndTime: null
      },

      scheme: {
        campaignName: {
          label: 'Campaign Name',
          type: 'text',
          placeholder: 'maximum length 50 characters',
          rules: [notEmpty, maxLength(50)].map(x => x.bind(this)),
          required: true,
          value: this.campaign.name
        },

        campaignDescription: {
          label: 'Description',
          type: 'text',
          placeholder: 'maximum length 65535 characters',
          rules: [notEmpty, maxLength(65535)].map(x => x.bind(this)),
          required: true,
          value: this.campaign.description
        },

        datetimePeriod: {
          label: 'Campaign Period',
          required: true,
          startDate:
            this.campaign.startTime && toDateObj(this.campaign.startTime),
          endDate: this.campaign.endTime && toDateObj(this.campaign.endTime)
        },

        datetimePeriodDisabled: {
          label: 'Campaign Period',
          required: true,
          value: this.campaign.startTime
            ? `${formatDatetimeFromISOString(
              this.campaign.startTime
            )} - ${formatDatetimeFromISOString(this.campaign.endTime)}`
            : ''
        },

        buttonCancel: {
          content: 'Cancel',
          size: 'large',
          outline: true,
          click: () => {
            this.$router.go(-1)
          }
        },

        buttonAddSegment: {
          content: 'Add Segment',
          size: 'medium',
          outline: true,
          prependIcon: 'add'
        },

        buttonSubmit: {
          content: 'Ok',
          size: 'large',
          click: this.submit
        }
      },
      tooltipScheme: {
        content: 'View Segment Content',
        direction: 'top',
        contentClass: 'tooltipClass'
      }
    }
  },
  computed: {
    isTimeEditable () {
      return (
        !this.campaign.startTime ||
        new Date(this.campaign.startTime) >= new Date()
      )
    }
  },
  async mounted () {
    this.$store.loading = true
    try {
      if (this.isTimeEditable && this.campaign.id) {
        this.calculateTaskTimes()
      }
      const { data } = await this.$Campaign.fetchSegmentList({
        segmentRequired: this.data.segments.map(x => x.id)
      })
      this.segmentOptions = data.map(segment => ({
        text: `(ID: ${segment.id}) ${segment.description}`,
        value: JSON.stringify(segment)
      }))
      const existedSegmentMap = this.data.segments.reduce(
        (map, segment) => map.set(segment.id, segment),
        new Map()
      )
      existedSegmentMap.forEach(segment => {
        const { deletable } = segment
        if (deletable) {
          this.selectedSegments.push(segment)
          existedSegmentMap.delete(segment.id)
        }
      })
      this.existedSegments = Array.from(existedSegmentMap).map(x => x[1])
    } catch (e) {
      this.$store.errorNotification = e
    }
    this.$store.loading = false
  },
  methods: {
    calculateTaskTimes () {
      const tasks = this.campaign.segments
        .map(x => x.groups)
        .flat()
        .map(x => x.tasks)
        .flat()
        .filter(x => x)
      this.task.minStartTime = tasks.reduce((time, { startTime }) => {
        if (!time || new Date(startTime) < new Date(time)) return startTime
        return time
      }, null)
      this.task.maxEndTime = tasks.reduce((time, { endTime }) => {
        if (!time || new Date(endTime) > new Date(time)) return endTime
        return time
      }, null)
    },
    onDatetimePickerChange (data) {
      this.data.startTime = localDateToISOString(data.startDate)
      this.data.endTime = localDateToISOString(data.endDate)
    },
    validate () {
      if (!this.$refs.form.$children[0].validate()) {
        throw this.$t('Campaign.form.validation_failed_general')
      }
      if (!this.data.startTime || !this.data.endTime) {
        throw this.$t('Campaign.form.period_missing_error')
      }
      if (
        this.task.minStartTime &&
        this.task.maxEndTime &&
        (new Date(this.data.startTime) >= new Date(this.task.minStartTime) ||
          new Date(this.data.endTime) <= new Date(this.task.maxEndTime))
      ) {
        throw this.$t('Campaign.form.period_error', {
          minTime: formatDatetimeFromISOString(this.task.minStartTime),
          maxTime: formatDatetimeFromISOString(this.task.maxEndTime)
        })
      }
      if (this.existedSegments.length + this.selectedSegments.length === 0) {
        throw this.$t('Campaign.form.segments_error')
      }
      this.isTimeEditable &&
        greaterThanCurrentTime.call(this, this.data.startTime)
    },
    async submit () {
      this.$store.loading = true
      try {
        this.validate()
        const segments = this.existedSegments
          .concat(this.selectedSegments)
          .map(s => s.id)
        const params = {
          name: this.scheme.campaignName.value,
          description: this.scheme.campaignDescription.value,
          startTime: this.data.startTime,
          endTime: this.data.endTime,
          segmentIds: segments
        }
        if (this.campaign.id) {
          const {
            data: { failedSegmentIds }
          } = await this.$Campaign.updateCampaign({
            id: this.campaign.id,
            ...params
          })
          this.showSuccessMessage({ isUpdate: true, failedSegmentIds })
        } else {
          const {
            data: { id, failedSegmentIds }
          } = await this.$Campaign.createCampaign(params)
          this.campaign.id = id
          this.showSuccessMessage({ isUpdate: false, failedSegmentIds })
        }
        this.$router.replace({
          name: 'CampaignDetail',
          params: { campaignId: this.campaign.id }
        })
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    showSuccessMessage ({ isUpdate, failedSegmentIds }) {
      let message = `Campaign successfully ${isUpdate ? 'updated' : 'created'}`
      if (failedSegmentIds) {
        this.$store.successNotification = `${message}, but some segments are failed to setup: ${failedSegmentIds}.
Please verify the segments on DataPlatform and try again later`
      } else {
        this.$store.successNotification = message
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
