<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="searchBar"
    >
      <h2
        class="headline font-weight-medium"
        @click="$refs.buttonSearch.click()"
      >
        Campaign List
      </h2>
      <v-spacer class="hidden-xs-only" />
      <x-formgroup
        v-model="scheme.valid"
        class="d-box-md-and-up w100-md-and-down"
      >
        <x-textfield
          v-model="scheme.campaignName.value"
          v-bind="scheme.campaignName"
          class="small"
          @keyup.enter="() => $refs.buttonSearch.click()"
        />
        <x-textfield
          v-model="scheme.campaignOwner.value"
          v-bind="scheme.campaignOwner"
          class="small"
          @keyup.enter="() => $refs.buttonSearch.click()"
        />
        <x-datetime-picker
          v-bind="scheme.datetimePicker"
          :start-date="startAfter"
          :end-date="startBefore"
          time-format="HH:mm"
          class="small"
          @onChange="onChangeHandler"
        />

        <x-button
          ref="buttonSearch"
          v-bind="buttonSearch"
          class="btnSecondary btnXsBlock"
        />
      </x-formgroup>
    </v-layout>
    <!-- END header -->

    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout
          row
          wrap
        >
          <x-button
            v-if="authenticate('CampaignCreate')"
            v-bind="buttonCreate"
            class="btnPrimary btnXsBlock"
          />
        </v-layout>
        <x-table
          v-bind="tableScheme"
          class="mt-3 campaignList"
        />
        <pagination
          ref="pager"
          :query="query"
          :fetch="$Campaign.fetchCampaignList"
          :callback="pagerCallback"
        />
      </div>
    </div>
    <!-- END content -->
  </div>
</template>

<script>
import { authenticate } from '../../../auth/allowAuthMapping.js'
import {
  formatDatetimeFromISOString,
  localDateToISOString
} from '../../../utils/date'
import Pagination from '@/components/Pagination'

export default {
  name: 'CampaignList',
  components: { Pagination },
  data () {
    return {
      query: {},
      startAfter: undefined,
      startBefore: undefined,

      scheme: {
        // BEGIN search
        campaignName: {
          type: 'text',
          name: 'campaignName',
          placeholder: 'Campaign Name'
        },

        campaignOwner: {
          type: 'text',
          name: 'campaignOwner',
          placeholder: 'Campaign Owner'
        },

        datetimePicker: {
          singleDate: false,
          alignRight: true
        }
      },

      buttonSearch: {
        content: 'Search',
        size: 'small',
        click: () => this.fetchCampaignList()
      },
      // END search

      buttonCreate: {
        content: 'Create Campaign',
        size: 'medium',
        prependIcon: 'add',
        click: () => {
          this.$router.push({
            name: 'CampaignCreate'
          })
        }
      },

      tableScheme: {
        multiSelectable: false,
        headers: [
          {
            value: 'no',
            text: 'No'
          },
          {
            value: 'id',
            text: 'Campaign ID',
            render: item =>
              `<span class="color-primary" data-link-to="CampaignDetail">${
                item.id
              }</span>`
          },
          {
            value: 'name',
            text: 'Campaign Name'
          },
          {
            value: 'ownerName',
            text: 'Owner'
          },
          {
            value: 'startTime',
            text: 'Start Date'
          },
          {
            value: 'endTime',
            text: 'End Date'
          },
          {
            value: 'createTime',
            text: this.$t('User.create_time')
          },
          {
            value: 'action',
            text: 'Actions'
          }
        ],
        items: [],
        actions: [
          {
            content: 'View',
            outline: true,
            click: (item, e) => {
              e.stopPropagation()
              this.$router.push({
                name: 'CampaignDetail',
                params: { campaignId: item.id }
              })
              return false
            }
          },
          {
            content: 'Delete',
            outline: true,
            class: 'actionWarning',
            render: () => authenticate('CampaignDelete'),
            click: this.deleteCampaignIntent
          }
        ],
        clickCallback: (item, e) => {
          if (e.target.dataset.linkTo === 'CampaignDetail') {
            this.$router.push({
              name: 'CampaignDetail',
              params: { campaignId: item.id }
            })
          }
        }
      }
    }
  },
  async mounted () {
    await this.fetchCampaignList()
  },
  methods: {
    authenticate (modelAction) {
      return authenticate(modelAction)
    },
    pagerCallback ({ campaigns }) {
      this.tableScheme.items = campaigns.map((campaign, ind) => {
        return {
          no: this.$refs.pager.offset + ind + 1,
          ...campaign,
          startTime: formatDatetimeFromISOString(campaign.startTime),
          endTime: formatDatetimeFromISOString(campaign.endTime),
          createTime: formatDatetimeFromISOString(campaign.createTime)
        }
      })
    },
    async fetchCampaignList () {
      this.query = {
        name: this.scheme.campaignName.value,
        owner: this.scheme.campaignOwner.value,
        startAfter: this.startAfter && localDateToISOString(this.startAfter),
        startBefore: this.startBefore && localDateToISOString(this.startBefore)
      }
    },
    onChangeHandler: function (data) {
      this.startAfter = data.startDate
      this.startBefore = data.endDate
    },
    async deleteCampaignIntent (campaign, e) {
      e.stopPropagation()
      this.$store.confirmDialog.push({
        message: 'Are you sure to remove this campaign? This cannot be undone.',
        onConfirm: () => this.deleteCampaign(campaign.id)
      })
      return false
    },
    async deleteCampaign (campaignId) {
      this.$store.loading = true
      try {
        const {
          data: { failedSegmentIds }
        } = await this.$Campaign.deleteCampaign({
          id: campaignId
        })
        let message = `Campaign has been successfully removed`
        if (failedSegmentIds) {
          this.$store.successNotification = `${message}, but some segments are failed to reset: ${failedSegmentIds}.
  Please verify the segments on DataPlatform and try again later`
        } else {
          this.$store.successNotification = message
        }
        await this.$refs.pager.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped></style>
