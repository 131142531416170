<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-3"
    >
      <h2 class="headline font-weight-medium">
        Campaign Detail
      </h2>
      <v-spacer class="hidden-xs-only" />
      <a
        :href="downloadCsvUrl"
        download
        class="btnLink w100-md-and-down"
      >
        <x-button
          v-bind="buttonDownload"
          class="btnSecondary btnXsBlock"
        />
      </a>
    </v-layout>
    <!-- END header -->
    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <x-tabs
          v-bind="tabsScheme"
          class="mt-2"
        >
          <!-- BEGIN tab 1 -->
          <div
            slot="tab1"
            class="campaign"
          >
            <hint class="mb-3">
              <a
                :href="dataPlatformUrl"
                target="_blank"
              >Go to Data platform generate customer list</a>
            </hint>

            <v-layout
              row
              wrap
            >
              <div class="ml-2">
                <p class="subheading font-weight-medium mb-2">
                  <label>Campaign Name : </label>
                  {{ campaign.name }}
                </p>
                <p>
                  <label class="labelStyle">Campaign Owner : </label>
                  {{ campaign.ownerName }}
                </p>
                <p>
                  <label class="labelStyle">Campaign ID : </label>
                  {{ campaign.id }}
                </p>
                <p>
                  <label class="labelStyle">Campaign Description : </label>
                  {{ campaign.description }}
                </p>
              </div>
              <v-spacer class="hidden-xs-only" />
              <x-button
                v-if="authenticate('CampaignEdit')"
                v-bind="buttonEditCampaign"
                class="btnPrimary btnLongText mb-2"
              />
            </v-layout>

            <v-expansion-panel
              v-model="expansion"
              expand
              class="mt-3"
            >
              <v-expansion-panel-content
                v-for="segment in segments"
                :key="segment.id"
                class="bgPaleBlue"
              >
                <div slot="header">
                  <p class="body-2 font-weight-medium">
                    <label>Segment Description : </label>
                    <span class="color-primary">{{ segment.description }}</span>
                  </p>
                  <p class="caption">
                    <label class="labelStyle">People Library ID : </label>
                    {{ segment.id }}
                  </p>
                  <p>
                    <a
                      :href="
                        `${dataPlatformUrl}/#/crm/viewPeopleLibrary?peopleLibraryId=${
                          segment.id
                        }`
                      "
                      target="_blank"
                      @click.stop="() => {}"
                    >View segment detail</a>
                  </p>
                </div>
                <v-card>
                  <v-card-text>
                    <datatable-rowspan
                      :segment="segment"
                      :campaign-id="campaignId"
                      @refresh="fetchCampaignDetail"
                    />
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
          <!-- END tab 1 -->
          <!-- BEGIN tab 2 -->
          <div slot="tab2">
            <hint>
              After you click the "submit" button of the task, the blast is
              scheduled and cannot be modified.
              <br>
              If you want to terminate the job, please contact "resource
              manager".
            </hint>
            <task-list-table
              :query="taskListQuery"
              :show-campaign="false"
            />
          </div>
          <!-- END tab 2 -->
        </x-tabs>
      </div>
    </div>
    <!-- END content -->
  </div>
</template>

<script>
import DatatableRowspan from '../components/DatatableRowspan'
import Hint from '@/components/Hint'
import TaskListTable from '@/modules/Task/components/TaskListTable.vue'
import { dataPlatform } from '@/const'
import { authenticate } from '@/auth/allowAuthMapping.js'
import company from '@/mixins/company'

export default {
  components: {
    DatatableRowspan,
    TaskListTable,
    Hint
  },
  mixins: [company],
  props: {
    campaignId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      campaign: {},
      dataPlatformUrl: dataPlatform.URL[this.$store.env],

      buttonDownload: {
        content: 'Download CSV',
        size: 'small',
        outline: true,
        prependIcon: 'cloud_download'
      },

      buttonEditCampaign: {
        content: 'Edit Campaign Setting / Segment',
        size: 'medium',
        outline: true,
        prependIcon: 'edit',
        click: () => {
          this.$router.push({
            name: 'CampaignEdit',
            params: { campaign: this.campaign }
          })
        }
      },

      tabsScheme: {
        type: 'line',
        tabs: [
          { label: 'Campaign Information', slot: 'tab1' },
          { label: 'Execution of Communication Task', slot: 'tab2' }
        ]
      },

      segments: {},
      expansion: [],

      taskListQuery: { campaignId: this.campaignId }
    }
  },
  computed: {
    downloadCsvUrl () {
      return `/api/v1/companies/${this.companyId}/campaigns/${
        this.campaignId
      }/csv`
    }
  },
  async mounted () {
    await this.fetchCampaignDetail()
  },
  methods: {
    async fetchCampaignDetail () {
      this.$store.loading = true
      try {
        const { data } = await this.$Campaign.fetchCampaignDetail({
          campaignId: this.campaignId
        })
        this.campaign = data
        this.segments = this.campaign.segments
        this.expansion = new Array(this.campaign.segments.length).fill(true)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    authenticate (modelAction) {
      return authenticate(modelAction)
    }
  }
}
</script>

<style lang="stylus" scoped>
.campaign {
  p {
    margin: 0;
  }
}

@media (max-width: 450px) {
  .campaign {
    .layout {
      flex-direction: column-reverse;
    }
  }
}
</style>
