<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="segment.groups"
      class="x-table elevation-1"
      hide-actions
    >
      <template
        slot="items"
        slot-scope="groups"
      >
        <tr>
          <td :rowspan="getGroupRowSpan(groups)">
            {{ groups.item.groupName }}
          </td>
          <td :rowspan="getGroupRowSpan(groups)">
            {{ groups.item.groupName }}
          </td>
          <td :rowspan="getGroupRowSpan(groups)">
            {{ groups.item.ratio }}
          </td>
          <td :rowspan="getGroupRowSpan(groups)">
            {{ numberWithCommas(groups.item.userCount) }}
          </td>
        </tr>
        <tr
          v-for="task of groups.item.tasks"
          :key="task.id"
        >
          <td class="border-right">
            {{ normalizeCount(task.count) }}
          </td>
          <td>
            <a
              @click="
                () =>
                  $router.push({
                    name: 'TaskDetail',
                    params: { taskId: task.id, viewOnly: true }
                  })
              "
            >{{ task.name }}</a>
          </td>
          <td>{{ task.channel }}</td>
          <td>{{ $t(`Task.status_list['${task.status}']`) }}</td>
          <td>{{ $t(`Task.execute_status_list['${task.executeStatus}']`) }}</td>
          <td class="dateTime">
            {{ formatDatetimeFromISOString(task.startTime) }}
          </td>
          <td class="dateTime">
            {{ formatDatetimeFromISOString(task.endTime) }}
          </td>
          <td class="dateTime">
            {{ formatDatetimeFromISOString(task.createTime) }}
          </td>
          <td class="actions-area">
            <x-button
              v-for="(scheme, ind) of taskActions.filter(
                scheme => !scheme.render || scheme.render(task)
              )"
              :key="ind"
              v-bind="scheme"
              :click="e => scheme.click(task, e)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-btn
              v-if="authenticate('TaskCreate')"
              outline
              small
              :disabled="!groups.item.isSend"
              color="blue darken-2"
              class="btnPrimary"
              @click="() => goToCreateTask(groups.item.groupId)"
            >
              Add Task
            </v-btn>
          </td>
          <!-- eslint-disable-next-line vue/require-v-for-key vue/no-unused-vars -->
          <td v-for="x of new Array(headers.length - 6)" />
        </tr>
      </template>
      <template slot="footer">
        <tr class="font-weight-bold">
          <td>Total</td>
          <td />
          <td>{{ totalPercentage }}%</td>
          <td>{{ numberWithCommas(totalUserCount) }}</td>
          <td :rowspan="headers.length - 5" />
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { authenticate } from '@/auth/allowAuthMapping.js'
import { numberWithCommas, normalizeCount } from '@/utils/number'
import { formatDatetimeFromISOString } from '@/utils/date'
import taskActionMixin, * as taskAction from '@/modules/Task/components/TaskActionMixin'

export default {
  mixins: [taskActionMixin],
  props: {
    campaignId: {
      type: String,
      required: true
    },
    segment: {
      type: Object,
      required: true
    }
  },
  data () {
    const {
      editButton,
      viewButton,
      submitButton,
      terminateButton,
      deleteButton
    } = taskAction.getButtons(this)
    return {
      headers: [
        'Group Type',
        'Group Name',
        '% of list',
        '# of list',
        '# of list sent/outbounded',
        'Task Name',
        'Channel Type',
        'Status',
        'Execute Status',
        'Start Date',
        'End Date',
        'Setup Date',
        'Actions'
      ].map(key => ({ text: key, sortable: false })),
      taskActions: [
        editButton,
        viewButton,
        submitButton,
        terminateButton,
        deleteButton
      ]
    }
  },
  computed: {
    totalPercentage () {
      return this.segment.groups.reduce((cnt, group) => (cnt += group.ratio), 0)
    },
    totalUserCount () {
      return this.segment.groups.reduce(
        (cnt, group) => (cnt += group.userCount),
        0
      )
    },
    totalTaskCount () {
      return this.segment.groups.reduce(
        (cnt, group) => (cnt += group.count.SMS + group.count.EDM),
        0
      )
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    getGroupRowSpan (groups) {
      return groups.item.tasks.length + 2
    },
    normalizeCount,
    numberWithCommas (n) {
      return numberWithCommas(n)
    },
    formatDatetimeFromISOString (x) {
      return formatDatetimeFromISOString(x)
    },
    goToCreateTask (groupId) {
      this.$router.push({
        name: 'TaskCreate',
        params: {
          campaignId: this.campaignId,
          groupId,
          segmentId: this.segment.id
        }
      })
    },
    authenticate (modelAction) {
      return authenticate(modelAction)
    }
  }
}
</script>

<style lang="stylus" scoped>
table {
  &.v-table {
      thead {
        th {
          font-size: $fs-regular;
        }
      }
      tbody {

        tr {
          border-bottom: $thin-solid;
          border-color: rgba($black, 0.12);

          td {
            font-size: $fs-regular;
            color: $label-grey;
            padding-top: 6px;
            padding-bottom: @padding-top;

            &:not(:first-child) {
              border-left: $thin-solid;
            }
            &.border-right {
              border-right: $thin-solid;
            }
            &.dateTime {
              min-width: 130px;
              font-size: $fs-small;
            }
          }
        }
      }
      tfoot {
        td {
          &:not(:first-child) {
            border-left: $thin-solid;
          }
        }
      }
  }
}
</style>
